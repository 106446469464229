import { Frontegg } from '@frontegg/vue'
import axios from 'axios'
import { createApp } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'

import i18n from '@/i18n'
import { store } from '@/store'
import { getPluginOptions } from '@jira/app'
import { LOGIN_APP_PLUGIN_OPTIONS } from '@root/template-options-keys'

import LoginApp from '../LoginApp'

import '@/assets/styles/root.scss'
import '@/assets/styles/app.scss'

export const configure = async () => {
  const rootElement = document.getElementById('app')

  const { apiBase, ...rest } = getPluginOptions(LOGIN_APP_PLUGIN_OPTIONS, rootElement)

  axios.defaults.baseURL = apiBase || window.origin
  axios.defaults.timeout = 50000

  const app = createApp(LoginApp)
  app.use(i18n)
  const router = createRouter({
    history: createWebHistory(),
    routes: []
  })

  app.use(router)
  app.use(store)

  await router.isReady()

  store.dispatch('webApp/setUrls', rest)

  app.use(Frontegg, {
    contextOptions: {
      baseUrl: process.env.VUE_APP_WEB_APP_POXY_URL,
      clientId: process.env.VUE_APP_WEB_APP_CLIENT_ID
    },
    authOptions: {
      // keepSessionAlive: true // Uncomment this in order to maintain the session alive
    },
    hostedLoginBox: false,
    router
  })

  app.mount(rootElement)
}

configure()
