<template>
  <OboardLogoLoader v-if="fronteggLoaded" class="la-Loader" />
</template>

<script>
// eslint-disable-next-line import/order
import { useFrontegg, useFronteggAuthGuard } from '@frontegg/vue'
import { computed, defineComponent, watch } from 'vue'
import { useRoute } from 'vue-router'
import { useStore } from 'vuex'

import WebAppAuthenticationApiHandler from '@/api/web-app-authentication'
import { handleError } from '@/utils/error-handling'

import OboardLogoLoader from '@/components/ui/SkeletonLoaders/OboardLogoLoader'

export default defineComponent({
  name: 'LoginApp',
  components: { OboardLogoLoader },

  setup() {
    useFronteggAuthGuard()
    const store = useStore()

    const appUrl = computed(() => store.state.webApp.urls.webAppUrl)

    const { fronteggLoaded, authState } = useFrontegg()
    const route = useRoute()
    const postLogin = async () => {
      const api = new WebAppAuthenticationApiHandler()

      try {
        await api.postLogin({ token: authState.user.accessToken })
        window.location.replace(appUrl.value)
      } catch (error) {
        handleError({ error })
      }
    }

    watch(
      () => [route.path, authState.isAuthenticated],
      (newValue, oldValue) => {
        const [newPath, newIsAuthenticated] = newValue
        const [oldPath, oldIsAuthenticated] = oldValue

        if (newPath === '/' && oldPath === authState.routes.activateUrl && newIsAuthenticated) {
          postLogin()
          return
        }

        const { routes = {} } = authState
        const routesWithoutPostLogin = [
          routes.acceptInvitationUrl,
          routes.logoutUrl,
          routes.activateUrl
        ]

        if (
          newIsAuthenticated &&
          oldIsAuthenticated === false &&
          !routesWithoutPostLogin.includes(newPath)
        ) {
          postLogin()
        }
      }
    )

    // watch(
    //   () => route.path,
    //   (newValue, oldValue) => {
    //     if (
    //       newValue === '/' &&
    //       oldValue === authState.routes.activateUrl &&
    //       authState.isAuthenticated
    //     ) {
    //       postLogin()
    //     }
    //   }
    // )
    //
    // watch(
    //   () => authState.isAuthenticated,
    //   (newValue, oldValue) => {
    //     const { routes = {} } = authState
    //     const routesWithoutPostLogin = [
    //       routes.acceptInvitationUrl,
    //       routes.logoutUrl,
    //       routes.activateUrl
    //     ]
    //
    //     if (newValue && oldValue === false && !routesWithoutPostLogin.includes(route.path)) {
    //       postLogin()
    //     }
    //   },
    //   { immediate: true }
    // )

    return {
      fronteggLoaded
    }
  }
})
</script>

<style lang="scss" scoped>
.la-Loader {
  --height: 100vh;
  position: relative;
  z-index: 0;
}
</style>
